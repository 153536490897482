<template>
  <v-scale-transition>
    <kits-panel :kits-items="dealerKits">
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Dealer Kits
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
import security from '@/security'

export default {
  components: {
    KitsPanel: () => import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  computed: {
    isImpersonated: function () {
      return this.$store.state.security.impersonate !== null
    },
    dealerKits: function () {
      const menus = [
        /*
        {
          icon: 'mdi-cast-connected',
          path: '/prepaid_kits',
          title: 'Prepaid',
          desc: 'Register prepaid subscribers & perform topup.',
        },
        */
        {
          icon: 'mdi-cast',
          path: '/black_kits',
          title: 'Black',
          desc: 'Register Black subscribers, change of plan and bill payment.',
        },
        {
          icon: 'mdi-calculator',
          path: '/ecash_kits',
          title: 'E-recharge',
          desc: 'Topup, transfer, purchase and pay postpaid bill using e-recharge.',
        },
      ]
      // security.me.dealerRank() !== 'Basic Dealer' && menus.push(
      //   {
      //     icon: 'mdi-account-network',
      //     path: '/dealership_kits',
      //     title: 'Dealership',
      //     desc: (security.me.dealerRank() === 'Area Dealer' || security.me.dealerRank() === 'Master Dealer')
      //       ? 'Appoint dealers, purchase and transfer dealership PINs.'
      //       : 'Purchase and transfer dealership PINs.',
      //   },
      // )
      security.me.dealerRank() !== ('SLD' || 'Basic Dealer') && menus.push(
        {
          icon: 'mdi-account-network',
          path: '/dealership_kits',
          title: 'Dealership',
          desc: (security.me.dealerRank() === 'Area Dealer' || security.me.dealerRank() === 'Master Dealer')
            ? 'Appoint dealers, purchase and transfer dealership PINs.'
            : 'Purchase and transfer dealership PINs.',
        },
      )
      menus.push(
        {
          icon: 'mdi-cellphone-charging',
          path: '/reload',
          title: 'Topup/Pay Bill',
          desc: 'Reload number.',
        },
        {
          icon: 'mdi-countertop-outline',
          path: '/upload_simcard',
          title: 'Upload SIM Pack',
          desc: 'Upload SIM for self activation by subscribers.',
        },
      )

      security.roles(['devop']) && menus.push(
        {
          icon: 'mdi-sim-outline',
          path: '/pickup_order',
          title: 'Pickup Order',
          desc: 'Simcard pickup order.',
        },
      )

      security.roles(['sales']) && menus.push(
        {
          icon: 'mdi-sim-outline',
          path: '/prepaidRegistration',
          title: 'Register Prepaid',
          desc: 'Register new Prepaid subscriber',
        },
      )

      return menus
    },
  },
}
</script>
